import React from "react";

import UnkoIcon from "../components/icons/unko-icon";

const Unko = () => {
  return (
    <div className="flex flex-col justify-center items-center w-screen h-screen text-red-500">
      <p className="mt-2 text-2xl font-semibold">My signature poop!</p>
      <UnkoIcon variant="h-full w-full" />
    </div>
  );
};

export default Unko;
